class Popups {
    constructor(selector) {
        if (selector) {
            this.init(selector);
        }
    }

    init(selector) {
        const self = this;
        
        $(document).on('click', selector, function(e) {
            let id = $(this).data('popup');
            let data = {
                text: $(this).data('text'),
                catalog: $(this).data('id'),
                object: $(this).data('object'),
                department: $(this).data('department')
            };
            
            self.open(id, data);
        });

        $('.popup').on('click', function(e) {
            if (!e.target.closest('.popup__container')) {
                self.close($(this));
            }
        })
    }


    open(popupId, data) {
        let popup = $('#' + popupId);
        let form = popup.find('.popup__form');
        let siteUrl = window.location.origin + '/' + (data.department ?? '');
        
        form.find('[name="siteurl"]').val(siteUrl);
        
        if (popupId == 'popup-catalog' && data) {
            popup.find('.popup__title').html(data.text);

            if (data.catalog) {
                form.append('<input type="hidden" name="catalog" value="' + data.catalog + '">');
            }

            if (data.object) {
                popup.find('[name="object"]').val(data.object);
            }
        }

        popup.addClass('show');
        $('body').addClass('hidden');

    }

    close(popup) {
        const timeout = 300;

        $(popup).addClass('hidden');
        $('body').removeClass('hidden');

        setTimeout(function () {
            $(popup).removeClass('show hidden');

            if (popup.hasClass('popup_catalog')) {
                popup.find('.popup__title').html('');
                popup.find('.popup__form').find('input[name="catalog"]').remove();
                popup.find('[name="object"]').val('');
            }
            
            popup.find('[name="siteurl"]').val('');
            
        }, timeout);
    }
}

export default Popups;