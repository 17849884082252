class Form {
    constructor(options) {
        this.getUtm();
        this.init(options);
    }
    reportError(data) {
        window.formErrorApi(data);
        alert('Кажется, что-то пошло не так. Повторите попытку позднее, спасибо.');
    }

    getSearchParams() {
        let paramsString = document.location.search,
            searchParams = new URLSearchParams(paramsString);
        return searchParams;
    }

    getUtm() {
        let utmNames = ['utm_source', 'utm_medium', 'utm_content', 'utm_term', 'utm_campaign'],
            utm = '';
        for (var i = 0; i < utmNames.length; i++) {
            var utmValue = this.getSearchParams().get(utmNames[i]);
            if (utmValue) utm += `&${utmNames[i]}=` + utmValue;
        }
        return utm;
    }

    presetationHandler(urlRedirect) {
        let winRef;
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        
        if (isSafari) winRef = window.open(window.location.href, "_blank");

        if (isSafari) {
            if (winRef == null) {
                window.location = urlRedirect;
            } else {
                winRef.location = urlRedirect;
            }

        } else {
            window.open(urlRedirect);
        }
    }

    g_send_goal(goal, category = null) {
        try {
            console.log('g_send_goal ' + goal + ' ' + category);
            if (typeof(ym) != "undefined") {
                ym(Ya.Metrika2.counters()[0].id, 'reachGoal', goal, null, function () {
                    console.log('ym ok');
                });
            }
            if (typeof(gtag) != "undefined") {
                gtag('event', goal, {
                    'event_category': category,
                    'event_callback': function () {
                        console.log('gtag ok');
                    }
                });
            }
            if (typeof(fbq) !== "undefined") {
                console.log('fbq track Lead');
                fbq('track', 'Lead');
            }
        } catch (e) {
            console.error(e.message);
        }
    }

    init(options) {
        let self = this;
        
        
        $(document).on('click', options.trigger, function (e) {
            e.preventDefault();
            let form = e.target.closest('form');
            let popup = form.closest('.popup');
            let field = $(form).find('[required]');
            let data = new FormData(form);
            let url = 'sendCRM.php';
            let empty = 0;

            field.each(function(index, el) {
                if ($(el).val()) {
                    $(el).removeClass('invalid');
                } else {
                    $(el).addClass('invalid');
                    empty++;
                }
            });
            
            if (empty === 0) {
                data = JSON.stringify(Object.fromEntries(data));
                
                $.ajax({
                    url: url,
                    type: "POST",
                    data: data,
                    complete: function (jqXHR) {
                        if (!jqXHR.responseJSON || jqXHR.responseJSON.status !== 'form_sent_ok') {
                            reportError(data);
                            return false;
                        }

                        if ($(form).data('goal') && $(form).data('goal-category')){
                            self.g_send_goal($(form).data('goal'), $(form).data('goal-category'));
                        }
                        
                        if (typeof jqXHR.responseJSON !== 'undefined' && typeof jqXHR.responseJSON.url !== 'undefined') {
                            self.presetationHandler(jqXHR.responseJSON.url);
                        } else {
                            alert('Ваша заявка отправлена!');
                        }

                        options.callback($(popup));
                    }
                });

                options.callback($(popup));
            }
        })
    }
}

export default Form;