export function scrollChecker(selector, visibleClass) {
    const items = document.querySelectorAll(selector);
    const options = {
        rootMargin: '0px',
        threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add(visibleClass);
            }
        });
    }, options);

    items.forEach(item => {
        observer.observe(item);
    });
}